import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Shine Supreme Car Wash and Detailing
			</title>
			<meta name={"description"} content={"Welcome to Shine Supreme, the ultimate destination for car enthusiasts who value perfection in car care. "} />
			<meta property={"og:title"} content={"Home | Shine Supreme Car Wash and Detailing"} />
			<meta property={"og:description"} content={"Welcome to Shine Supreme, the ultimate destination for car enthusiasts who value perfection in car care. "} />
			<meta property={"og:image"} content={"https://fronvest.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fronvest.live/img/download.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fronvest.live/img/download.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fronvest.live/img/download.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fronvest.live/img/download.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fronvest.live/img/download.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fronvest.live/img/download.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="112px 0 0px 0" background="--color-darkL2" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 40px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Shine Supreme Car Wash and Detailing
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					width="70%"
				>
					Welcome to Shine Supreme, the ultimate destination for car enthusiasts who value perfection in car care. Our facility combines the latest in car wash technology with the meticulous touch of professional detailing, ensuring your vehicle looks its absolute best.
				</Text>
				<Link
					href="/contact"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s linear 0s"
					hover-background="--color-secondary"
					transition="background-color 0.2s linear 0s"
				>
					Visit Us Today
				</Link>
			</Box>
			<Box
				display="flex"
				width="100%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://fronvest.live/img/1.jpg"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" quarkly-title="Advantages/Features-24">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--base"
					lg-text-align="center"
					lg-width="100%"
					text-transform="uppercase"
					letter-spacing="1px"
					color="--secondary"
				>
					About Shine Supreme
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					lg-width="100%"
				>
					Why Choose Shine Supreme?
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Icon
						category="md"
						icon={MdDone}
						size="36px"
						background="--color-secondary"
						color="--light"
						border-radius="50%"
						padding="8px 8px 8px 8px"
					/>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text
							margin="0px 0px 6px 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							sm-font="--lead"
						>
							Advanced Cleaning Technology:{" "}
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							We use eco-friendly, state-of-the-art equipment to ensure a{" "}
							<br />
							thorough and safe clean for your vehicle.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Icon
						category="md"
						icon={MdDone}
						size="36px"
						background="--color-secondary"
						color="--light"
						border-radius="50%"
						padding="8px 8px 8px 8px"
					/>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text
							margin="0px 0px 6px 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							sm-font="--lead"
						>
							Expert Detailing Services:
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							From interior shampooing to exterior polishing, our{" "}
							<br />
							detailing experts are trained to revitalize every inch of your car.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="100%"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Icon
						category="md"
						icon={MdDone}
						size="36px"
						background="--color-secondary"
						color="--light"
						border-radius="50%"
						padding="8px 8px 8px 8px"
					/>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text
							margin="0px 0px 6px 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							sm-font="--lead"
						>
							Customized Care Plans:
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							We offer a range of packages tailored to your car's specific{" "}
							<br />
							needs and your personal preferences.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://fronvest.live/img/2.jpg"
					max-width="400px"
					border-radius="8px"
					height="100%"
					object-fit="cover"
					lg-width="100%"
					lg-max-width="none"
				/>
			</Box>
		</Section>
		<Components.New />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});